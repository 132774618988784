import {SET_APP} from "app/actions/app";
import {SET_ORDERS} from "app/actions/orders";

export default function orders(state, action) {
  switch(action.type) {
    case SET_APP: {
      const data = action.payload.orders;

      return data ? [...data] : [];
    }
    case SET_ORDERS: {
      const data = action.payload;

      return data ? [...data] : [];
    }
    default:
      return state;
  }
}
