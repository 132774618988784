import PropTypes from "prop-types";
import {useState} from "react";
import Order from "components/helpers/order";
import PageButtons from "components/helpers/page-buttons";

function Orders({records}) {
  const [index, setIndex] = useState(0);
  const record = records[index];
  if(!record) { return null; }

  return (
    <>
      <h1 className="text-center">Orders</h1>
      <Order admin={true} record={record} />
      <div className="mt-3 text-center">
        <PageButtons
          onBack={() => { setIndex(index - 1); }}
          onFirst={() => { setIndex(0); }}
          onLast={() => { setIndex(records.length - 1); }}
          onNext={() => { setIndex(index + 1); }}
          page={index + 1}
          total={records.length}
        />
      </div>
    </>
  );
}

Orders.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default Orders;
