import PropTypes from "prop-types";

function PageButtons({onBack, onFirst, onLast, onNext, page, total}) {
  return (
    <div className="btn-group">
      {page > 1 ? (
        <>
          <button className="btn btn-themed" onClick={onFirst} type="button">
            <i className="fas fa-angle-double-left" />
          </button>
          <button className="btn btn-themed" onClick={onBack} type="button">
            <i className="fas fa-angle-left" />
          </button>
        </>
      ) : (
        <>
          <button className="btn btn-themed disabled" type="button">
            <i className="fas fa-angle-double-left" />
          </button>
          <button className="btn btn-themed disabled" type="button">
            <i className="fas fa-angle-left" />
          </button>
        </>
      )}
      <button className="btn btn-themed disabled" type="button">{page}</button>
      {page >= total ? (
        <>
          <button className="btn btn-themed disabled" type="button">
            <i className="fas fa-angle-right" />
          </button>
          <button className="btn btn-themed disabled" type="button">
            <i className="fas fa-angle-double-right" />
          </button>
        </>
      ) : (
        <>
          <button className="btn btn-themed" onClick={onNext} type="button">
            <i className="fas fa-angle-right" />
          </button>
          <button className="btn btn-themed" onClick={onLast} type="button">
            <i className="fas fa-angle-double-right" />
          </button>
        </>
      )}
    </div>
  );
}
PageButtons.propTypes = {
  onBack: PropTypes.func.isRequired,
  onFirst: PropTypes.func.isRequired,
  onLast: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default PageButtons;
