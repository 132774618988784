import PropTypes from "prop-types";
import {useState} from "react";

function Product({addToCart, record}) {
  const [variant, setVariant] = useState(record.variants[0]);
  const onChange = (e) => setVariant(record.variants.find(({id}) => id === +e.target.value));
  const hideSize = record.variants.every(({size}) => size === record.variants[0].size);
  const htmlID = `product-variant-${variant.id}`;

  return (
    <div className="col-md-4 p-4">
      <img alt={record.name} className="img-fluid" src={record.thumbnail_url} />
      <h4 className="mt-2 p-0 text-center">{record.name}</h4>
      <div className="d-flex justify-content-between">
        <div className="input-group">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={htmlID}>Options</label>
          </div>
          <select className="form-control" id={htmlID} name="variant" onChange={onChange} value={variant.id}>
            {record.variants.map(({color, id, retail_price: price, size}) => (
              <option key={id} value={id}>{[color, !hideSize && size, `$${price}`].filter(Boolean).join(" / ")}</option>
            ))}
          </select>
        </div>
        <button className="ml-2" onClick={() => addToCart(variant)} type="button">
          <i className="fas fa-plus" />
        </button>
      </div>
    </div>
  );
}

Product.propTypes = {
  addToCart: PropTypes.func.isRequired,
  record: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        retail_price: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

export default Product;
