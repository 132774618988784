import {useEffect, useState} from "react";
import {setOrders} from "app/actions/orders";
import {getAll as getRecords} from "app/requests/store/orders";
import useAppContext from "lib/hooks/use-app-context";
import Order from "components/helpers/order";
import PageButtons from "components/helpers/page-buttons";

export default function Orders() {
  const [{orders: records}, dispatch] = useAppContext();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    getRecords().then((data) => dispatch(setOrders(data.records)));
  }, []);

  if(!records) { return null; }

  const record = records[index];
  if(!record) { return null; }

  return (
    <div className="col-md-4">
      <h3>Orders</h3>
      <Order record={record} />
      <div className="mt-3">
        <PageButtons
          onBack={() => { setIndex(index - 1); }}
          onFirst={() => { setIndex(0); }}
          onLast={() => { setIndex(records.length - 1); }}
          onNext={() => { setIndex(index + 1); }}
          page={index + 1}
          total={records.length}
        />
      </div>
    </div>
  );
}
