import {useEffect, useState} from "react";
import {getAll as getOrders} from "app/requests/admin/orders";
import {Loading} from "components/pages";
import Row from "./row";

export default function AdminOrdersList() {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    getOrders().then((data) => setRecords(data.records));
  }, []);

  if(!records) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1 className="text-center">Orders</h1>
          <div className="table-responsive rounded">
            <table className="table table-hover text-center">
              <thead className="thead-dark">
                <tr>
                  <th colSpan="2">Recipient</th>
                  <th>Status</th>
                  <th>Items</th>
                  <th>Total</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {records.map((order) => (
                  <Row key={order.id} order={order} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
