import {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";
import useLoadStripe from "lib/hooks/use-load-stripe";
import useQueryParams from "lib/hooks/use-query-params";
import Container from "./container";

export default function Cart() {
  const [{cart, stripe}] = useAppContext();
  const params = useQueryParams();
  const [redirect, setRedirect] = useState(null);
  const clientSecret = cart.stripe_key || params.get("payment_intent_client_secret");

  useLoadStripe();

  useEffect(() => {
    if(!clientSecret) { return; }
    if(!stripe) { return; }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      if(!["processing", "succeeded"].includes(paymentIntent.status)) { return; }

      setRedirect(`/store/order?payment_intent_client_secret=${clientSecret}`);
    });
  }, [clientSecret, stripe]);

  if(redirect) { return <Redirect to={redirect} />; }

  return <Container />;
}
