import {useState} from "react";
import {Redirect} from "react-router-dom";
import {resetCart} from "app/actions/cart";
import {destroy as destroyCart} from "app/requests/store/cart";
import useAppContext from "lib/hooks/use-app-context";

export default function Items() {
  const [{cart}, dispatch] = useAppContext();
  const [redirect, setRedirect] = useState(null);

  const items = cart.items || [];
  const total = items.map((variant) => variant.quantity * +variant.retail_price)
    .reduce((a, b) => a + b, 0)
    .toFixed(2);
  const clearCart = () => {
    destroyCart().then(() => {
      dispatch(resetCart());

      setRedirect("/store");
    });
  };

  if(redirect) { return <Redirect to={redirect} />; }
  if(items.length === 0) { return null; }

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-6 text-left mb-1">
          <h2>Cart</h2>
        </div>
        <div className="col-6 text-right mb-1">
          <button className="btn btn-danger mr-2" onClick={clearCart} type="button">Clear</button>
          <a className="btn btn-themed" href="/store">Edit</a>
        </div>
      </div>
      {items.map((variant) => (
        <div key={variant.id} className="d-flex justify-content-between">
          <div>{variant.name}</div>
          <div>{variant.quantity} x ${variant.retail_price}</div>
        </div>
      ))}
      <hr />
      <div className="d-flex justify-content-between">
        <div>Subtotal</div>
        <div>${total}</div>
      </div>
    </div>
  );
}
