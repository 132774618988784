import {useEffect} from "react";
import {Redirect} from "react-router-dom";
import {setCart} from "app/actions/cart";
import {createNotification} from "app/actions/notifications";
import {update as updateCart} from "app/requests/store/cart";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import Items from "./items";
import Price from "./price";
import ShippingRate from "./shipping/rate";
import ShippingRecipient from "./shipping/recipient";

export default function Container() {
  const [{cart}, dispatch] = useAppContext();

  useEffect(() => {
    if(!cart.dirty) { return; }

    updateCart({cart: {items: cart.items}}).then((data) => {
      if(data.error) {
        return dispatch(createNotification({content: data.error, type: "danger"}));
      }

      dispatch(setCart(data.record));
    });
  }, []);

  if(cart.dirty) { return <Loading />; }
  if(cart.items && cart.items.length === 0) { return <Redirect to="/store" />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Items />
          <ShippingRecipient />
          <ShippingRate />
          <Price />
        </div>
      </div>
    </div>
  );
}
