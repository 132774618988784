import PropTypes from "prop-types";
import useAppContext from "lib/hooks//use-app-context";
import {capitalize} from "lib/string";

function Order({admin = false, record}) {
  const [{app: {environment}}] = useAppContext();

  const development = environment !== "production";
  const prices = record.pricing && ["subtotal", "shipping", "discount", "service_fee", "total"]
    .filter((name) => record.pricing[name])
    .map((name) => ({name, price: record.pricing[name]}));
  const stripeURL = development
    ? "https://dashboard.stripe.com/test"
    : "https://dashboard.stripe.com";
  let status = record.status || "Blank";
  if(!admin && status.includes("issue")) { status = "The is an issue"; }

  return (
    <div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="order-uid">UID</label>
        </div>
        <input
          className="form-control"
          id="order-uid"
          name="uid"
          readOnly={true}
          type="text"
          value={record.uid}
        />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="order-email">Email</label>
        </div>
        <input
          className="form-control"
          id="order-email"
          name="email"
          readOnly={true}
          type="email"
          value={record.recipient?.email || "Blank"}
        />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="order-name">Recipient</label>
        </div>
        <input
          className="form-control"
          id="order-name"
          name="name"
          readOnly={true}
          type="text"
          value={record.recipient?.name || "Blank"}
        />
      </div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="order-status">Status</label>
        </div>
        <input
          className="form-control"
          id="order-status"
          name="status"
          readOnly={true}
          type="text"
          value={status}
        />
      </div>
      {record.items.map((variant) => (
        <div key={variant.id} className="d-flex justify-content-between">
          <div>{variant.name}</div>
          <div>{variant.quantity} x ${variant.retail_price}</div>
        </div>
      ))}
      {record.shipping_rate && <hr />}
      {record.shipping_rate && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{record.shipping_rate.name}</div>
          <div>${record.shipping_rate.rate}</div>
        </div>
      )}
      {prices && <hr />}
      {prices?.map(({name, price}) => (
        <div key={name} className="d-flex justify-content-between">
          <div>{name.split("_").map(capitalize).join(" ")}</div>
          <div>${price.toFixed(2)}</div>
        </div>
      ))}
      {admin && (
        <div className="text-center">
          <div className="btn-group mt-3">
            {record.user && (
              <a className="btn btn-sm btn-primary" href={`/admin/users/${record.user.id}`}>User</a>
            )}
            <a className="btn btn-sm btn-secondary" href={`https://www.printful.com/dashboard/default/orders?id=${record.uid}`} rel="noreferrer" target="_blank">Printful</a>
            {record.stripe_id && (
              <a className="btn btn-sm btn-warning" href={`${stripeURL}/payments/${record.stripe_id}`} rel="noreferrer" target="_blank">Stripe</a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Order.propTypes = {
  admin: PropTypes.bool,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        retail_price: PropTypes.string.isRequired
      }).isRequired
    ),
    pricing: PropTypes.shape({
      discount: PropTypes.number,
      service_fee: PropTypes.number,
      shipping: PropTypes.number,
      subtotal: PropTypes.number,
      total: PropTypes.number
    }),
    recipient: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string
    }),
    shipping_rate: PropTypes.shape({
      name: PropTypes.string,
      rate: PropTypes.string
    }),
    status: PropTypes.string.isRequired,
    stripe_id: PropTypes.string,
    uid: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Order;
