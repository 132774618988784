import {useState} from "react";
import {setCart} from "app/actions/cart";
import {createNotification} from "app/actions/notifications";
import {update as updateCart} from "app/requests/store/cart";
import useAppContext from "lib/hooks/use-app-context";
import useDidUpdate from "lib/hooks/use-did-update";

export default function ShippingRate() {
  const [{cart: {shipping_rate: rate, shipping_rates: rates}}, dispatch] = useAppContext();
  const [open, setOpen] = useState(!rate);

  useDidUpdate(() => { if(rates && !rate) { setOpen(true); } }, [rates]);

  if(!rates) { return null; }
  if(!open) {
    if(!rate) { return null; }

    return (
      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h2 className="m-0">Shipping Rates</h2>
          <button className="btn btn-themed" onClick={() => setOpen(true)} type="button">Edit</button>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>{rate.name}</div>
          <div>${rate.rate}</div>
        </div>
      </div>
    );
  }

  const onClick = (record) => {
    updateCart({cart: {shipping_rate_id: record.id}}).then((data) => {
      if(data.error) {
        return dispatch(createNotification({content: data.error, type: "danger"}));
      }

      dispatch(setCart(data.record));
      setOpen(false);
    });
  };

  return (
    <div className="mb-4">
      <h2>Shipping Rates</h2>
      {rates.map((record) => (
        <div key={record.id} className="d-flex align-items-center justify-content-between my-2">
          <div>{record.name}</div>
          <div className="d-flex align-items-center">
            <div className="mx-3">${record.rate}</div>
            <button className="btn btn-sm btn-primary" onClick={() => onClick(record)} type="button">Select</button>
          </div>
        </div>
      ))}
    </div>
  );
}
