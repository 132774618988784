import PropTypes from "prop-types";
import useAppContext from "lib/hooks//use-app-context";
import {displayDate} from "lib/string";

function AdminOrdersListRow({order}) {
  const [{app: {environment}}] = useAppContext();
  const development = environment !== "production";

  const stripeURL = development
    ? "https://dashboard.stripe.com/test"
    : "https://dashboard.stripe.com";

  return (
    <tr>
      <td>{order.recipient?.name || "None"}</td>
      <td>{order.recipient?.email || "None"}</td>
      <td>{order.status}</td>
      <td>{order.items ? order.items.reduce((sum, {quantity}) => sum + quantity, 0) : 0}</td>
      <td>{order.pricing ? `$${order.pricing.total.toFixed(2)}` : "$0"}</td>
      <td>{displayDate(order.created_at)}</td>
      <td>{displayDate(order.updated_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/orders/${order.id}`}>View</a>
          {order.user && (
            <a className="btn btn-sm btn-secondary" href={`/admin/users/${order.user.id}`}>User</a>
          )}
          <a className="btn btn-sm btn-warning" href={`https://www.printful.com/dashboard/default/orders?id=${order.uid}`} rel="noreferrer" target="_blank">Printful</a>
          {order.stripe_id && (
            <a className="btn btn-sm btn-success" href={`${stripeURL}/payments/${order.stripe_id}`} rel="noreferrer" target="_blank">Stripe</a>
          )}
        </div>
      </td>
    </tr>
  );
}

AdminOrdersListRow.propTypes = {
  order: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number
      }).isRequired
    ),
    pricing: PropTypes.shape({
      total: PropTypes.number
    }),
    recipient: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string
    }),
    status: PropTypes.string.isRequired,
    stripe_id: PropTypes.string,
    uid: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired
};

export default AdminOrdersListRow;
