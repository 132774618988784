import {Elements} from "@stripe/react-stripe-js";
import useAppContext from "lib/hooks/use-app-context";
import {capitalize} from "lib/string";
import Checkout from "./checkout";

export default function Price() {
  const [{cart: {pricing, stripe_key: clientSecret}, stripe}] = useAppContext();

  if(!pricing) { return null; }
  if(!stripe) { return null; }

  const prices = ["subtotal", "shipping", "discount", "service_fee"]
    .filter((name) => pricing[name])
    .map((name) => ({name, price: pricing[name]}));

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-12 text-left mb-1">
          <h2>Price</h2>
        </div>
      </div>
      {prices.map(({name, price}) => (
        <div key={name} className="d-flex justify-content-between">
          <div>{name.split("_").map(capitalize).join(" ")}</div>
          <div>${price.toFixed(2)}</div>
        </div>
      ))}
      <hr />
      <div className="d-flex justify-content-between">
        <div>Total</div>
        <div>${pricing.total.toFixed(2)}</div>
      </div>
      <div className="mt-4 row">
        <div className="col-12 text-left mb-1">
          <h2>Checkout</h2>
        </div>
      </div>
      <Elements options={{clientSecret}} stripe={stripe}>
        <Checkout />
      </Elements>
    </div>
  );
}
