import {
  SET_CART,
  SET_ITEMS,
  RESET_CART
} from "app/actions/cart";
import Cache from "lib/cache";

export default function cart(state, action) {
  switch(action.type) {
    case RESET_CART: {
      Cache.remove("cart");

      return {};
    }
    case SET_CART: {
      const data = action.payload;

      Cache.set("cart", data);

      return {...data};
    }
    case SET_ITEMS: {
      const data = {...state, dirty: true, items: action.payload};

      Cache.set("cart", data);

      return {...data};
    }
    default:
      return state;
  }
}
