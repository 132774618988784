import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {useState} from "react";

export default function Checkout() {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const paymentElementOptions = {layout: "tabs"};
  const onSubmit = (e) => {
    e.preventDefault();

    if(!stripe || !elements) { return; }

    setLoading(true);

    stripe.confirmPayment({
      confirmParams: {return_url: `${window.location.origin}/store/cart`},
      elements
    }).then(({error}) => {
      if(error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }

      setLoading(false);
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <div className="form-group mt-3">
        <button
          className="btn btn-primary px-5"
          disabled={loading || !stripe || !elements}
          type="submit"
        >
          {loading ? "Loading" : "Pay Now"}
        </button>
      </div>
      {message && <div>{message}</div>}
    </form>
  );
}
