import {loadStripe} from "@stripe/stripe-js";
import {useEffect} from "react";
import {setStripe} from "app/actions/stripe";
import useAppContext from "lib/hooks/use-app-context";

export default function useLoadStripe() {
  const [{app}, dispatch] = useAppContext();

  useEffect(() => {
    if(!app.stripe_public_key) { return; }

    loadStripe(app.stripe_public_key).then((loadedStripe) => {
      dispatch(setStripe(loadedStripe));
    });
  }, [app.stripe_public_key]);
}
