import {useEffect, useState} from "react";
import {setItems} from "app/actions/cart";
import {createNotification} from "app/actions/notifications";
import {getAll} from "app/requests/store/products";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import useLoadStripe from "lib/hooks/use-load-stripe";
import {mutable} from "lib/object";
import Product from "./product";

export default function Store() {
  const [{cart}, dispatch] = useAppContext();
  const [records, setRecords] = useState(null);
  const items = cart.items || [];

  useLoadStripe();

  useEffect(() => {
    getAll().then((data) => setRecords(data.records));
  }, []);

  if(!records) { return <Loading />; }

  const modifyCart = (variant, change) => {
    let updatedItems = mutable(items);
    const updatedItem = updatedItems.find(({id}) => id === variant.id) || {...variant, quantity: 0};
    updatedItem.quantity += change;
    updatedItems = updatedItems.filter(({id}) => id !== variant.id)
      .concat(updatedItem)
      .filter(({quantity}) => quantity > 0)
      .sort((a, b) => a.name.localeCompare(b.name));

    dispatch(setItems(updatedItems));
  };
  const removeFromCart = (variant) => {
    const updatedItems = [...items].filter(({id}) => id !== variant.id);

    dispatch(createNotification({content: `${variant.name} removed from cart`, type: "danger"}));
    dispatch(setItems(updatedItems));
  };
  const addToCart = (variant) => {
    dispatch(createNotification({content: `${variant.name} added to cart`, type: "success"}));
    modifyCart(variant, 1);
  };
  const total = items.map((variant) => variant.quantity * +variant.retail_price)
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

  return (
    <div className="container-fluid">
      {items.length > 0 && (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h2 className="text-center">Store</h2>
            {items.map((variant) => (
              <div key={variant.id} className="d-flex justify-content-between">
                <div className="d-flex">
                  <div>{variant.name}</div>
                  <button className="mx-2" onClick={() => removeFromCart(variant)} type="button">
                    <i className="fas fa-times" />
                  </button>
                </div>
                <div className="d-flex">
                  <button className="mr-2" onClick={() => modifyCart(variant, -1)} type="button">
                    <i className="fas fa-minus" />
                  </button>
                  <button className="mr-2" onClick={() => modifyCart(variant, 1)} type="button">
                    <i className="fas fa-plus" />
                  </button>
                  <div>{variant.quantity} x ${variant.retail_price}</div>
                </div>
              </div>
            ))}
            <hr />
            <div className="d-flex justify-content-between">
              <div>Subtotal</div>
              <div>${total}</div>
            </div>
            <div className="text-center">
              <a className="btn btn-themed" href="/store/cart">Checkout</a>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        {records.map((record) => (
          <Product key={record.id} addToCart={addToCart} record={record} />
        ))}
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="text-center">
            <a className="btn btn-themed" href="/store/cart">Checkout</a>
          </div>
        </div>
      </div>
    </div>
  );
}
