import {SET_STRIPE} from "app/actions/stripe";

export default function stripe(state, action) {
  switch(action.type) {
    case SET_STRIPE: {
      return action.payload;
    }
    default:
      return state;
  }
}
