import {useEffect, useState} from "react";
import {resetCart} from "app/actions/cart";
import {destroy as destroyCart} from "app/requests/store/cart";
import useAppContext from "lib/hooks/use-app-context";
import useLoadStripe from "lib/hooks/use-load-stripe";
import useQueryParams from "lib/hooks/use-query-params";

export default function Order() {
  const [{stripe}, dispatch] = useAppContext();
  const clientSecret = useQueryParams().get("payment_intent_client_secret");
  const [message, setMessage] = useState(null);
  const [paid, setPaid] = useState(false);

  useLoadStripe();

  useEffect(() => {
    if(!clientSecret) { return; }
    if(!stripe) { return; }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      switch(paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setPaid(true);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [clientSecret, stripe]);

  useEffect(() => {
    if(!paid) { return; }

    destroyCart().then(() => { dispatch(resetCart()); });
  }, [paid]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 offset-md-2 text-center">
          <h2>Order Processing</h2>
          <p>Your order is currently being processed! Check your email for any updates.</p>
          {message && <div>{message}</div>}
        </div>
      </div>
    </div>
  );
}
