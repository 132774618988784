import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getRecord} from "app/requests/admin/orders";
import Loading from "components/pages/loading";
import Order from "components/helpers/order";

export default function AdminOrdersShow() {
  const {id} = useParams();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    getRecord(id).then((data) => setRecord(data.record));
  }, []);

  if(!record) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <h1 className="text-center">Order</h1>
          <Order admin={true} record={record} />
        </div>
      </div>
    </div>
  );
}
